import React, { useEffect, useState } from 'react'
import { graphql, Link } from 'gatsby'
import { Helmet } from 'react-helmet'
import { useQueryParam, StringParam } from 'use-query-params'


import LearnNav from '../../components/LearnNav'
import LearningPathNotification from '../components/LearningPathNotification'
import './tutorialDetail.scss'
import RightSidebar from '../components/RightSidebar'

export default function TutorialDetail({ path, location, data }) {
  const { tutorial, tutorials, learningPaths } = data

  const [isLearning, setIsLearning] = useState(false);
  const learningPath = useQueryParam('learningPath', StringParam)[0]
  var tutorialSet = []
  var nextPath = ''

  useEffect(() => {
    setIsLearning(learningPath !== undefined)
  }, [isLearning, learningPath])

  const associatedLearningPaths = learningPaths.nodes.filter(
    elem => elem.frontmatter.tutorials.includes(
      tutorial.frontmatter.path.substring(1),
    ),
  )
  const hideLearnNotify = isLearning || associatedLearningPaths.length === 0

  var thisLearningPath = {}
  if (isLearning) {
    if (learningPath === '') {
      return
    } else {
      thisLearningPath = learningPaths.nodes.filter(
        elem => elem.frontmatter.path.substring(1) === learningPath,
      )
    }

    const tutorialPaths = thisLearningPath[0].frontmatter.tutorials

    tutorialPaths.forEach((path) => {
      const thisTut = tutorials.nodes.filter(
        elem => elem.frontmatter.path.substring(1) === path,
      )
      tutorialSet.push({
        path: path,
        short_title: thisTut[0].frontmatter.short_title,
        learningPath: learningPath,
      })
    })

    let currentTutorialIndex
    tutorialSet.forEach((elem, i) => {
      if (elem.path === tutorial.frontmatter.path.substring(1)) {
        currentTutorialIndex = i
      }
    })
    nextPath = (currentTutorialIndex + 1 < tutorialSet.length)
      ? tutorialSet[currentTutorialIndex + 1].path
      : 'end'
  }

  return (
    <div className={`page--tutorial-details-container`}>
      <div style={{position: "fixed"}}>
      <Helmet title={isLearning ? `Learning Path - ${tutorial.frontmatter.title} | Couchbase Developer Portal` : `Tutorial - ${tutorial.frontmatter.title} | Couchbase Developer Portal`} />
      </div>
      <div className='flex flex-col sm:flex-col md:flex-row lg:flex-row'>
        {isLearning
          &&
          <div className={`${isLearning ? 'learn-nav-container' : 'hidden'}`}>
            <LearnNav learningPath={thisLearningPath[0]} path={path.substring(1)} tutorialSet={tutorialSet} />
          </div>
        }
        <div className={`${isLearning === true ? 'learn-path-container' : 'w-full tutorial-container'}`}>
          <h1>{tutorial.frontmatter.title}</h1>
          <ul>
            {tutorial.frontmatter.description.map((descriptionBullet) => {
              return (<li key={descriptionBullet}>{descriptionBullet}</li>)
            })}
          </ul>
          <div className='markdown-innerhtml' dangerouslySetInnerHTML={{ __html: tutorial.html }} />
          {isLearning ?
            <div className='next-tutorial-in-learning-path'>
              <hr className='h-1 mt-4' />
              {(isLearning && nextPath && nextPath !== 'end')
                ? <div className='mt-4 mb-4 text-gray-600 text-right'>
                  <Link className='cb-red-button' exact='true' activeClassName='active'
                    alt='Visit Couchbase Playground'
                    to={`/${nextPath}?learningPath=${learningPath}`}
                  >Next Step</Link>
                </div>
                : <div className='mt-4 mb-4 text-gray-600 text-right'>
                  <div className='font-semibold'>Congratulations, you have reached the end of the Learning Path.</div>
                </div>
              }
            </div>
            : null
          }
          <hr className={`${hideLearnNotify ? 'hidden' : ''} my-2`} />
          {!isLearning
            ? <LearningPathNotification associatedLearningPaths={associatedLearningPaths}
              hideLearnNotify={hideLearnNotify} tutorial={tutorial}
              allTutorials={tutorials} />
            : null
          }
        </div>
        <RightSidebar tutorial={tutorial} />
      </div>
    </div>
  )
}

export const tutorialLearnTemplateQuery = graphql`
  query TutorialLearnTemplateQuery($path: String!) {
    tutorial: markdownRemark(frontmatter: { path: { eq: $path } }) {
      id
      html
      frontmatter {
        content_type
        description
        length
        path
        related_paths
        tags
        technology
        title
        short_title
        tutorials
      }
      headings {
        value
        depth
        id
      }
    }
    tutorials: allMarkdownRemark(filter: { frontmatter: { content_type: { in: ["tutorial", "quickstart"] }, } }) {
      nodes {
        id
        frontmatter {
          title
          short_title
          path
        }
      }
    }
    learningPaths: allMarkdownRemark(filter: { frontmatter: { content_type: { eq: "learn" } } }) {
      nodes {
        id
        frontmatter {
          title
          short_title
          path
          tutorials
        }
      }
    }
  }
`
