import React  from 'react'
import './../../styles/modules/RightSidebar.scss'

function RightSidebar({ tutorial }) {
  const handleClickAndKeyPress = (heading) => {
    let id = heading["id"]
    let scrollDiv = document.getElementById(id).offsetTop;
    window.scrollTo({ top: scrollDiv -150, behavior: 'smooth'});
  };

    return (
        <div className='tableOfContents' >
            <span className='RightSidebarTitle' key="contentHeader">Contents</span>
            <div className='contentTitles'>
            {
                tutorial.headings.map((heading) => {
                  return (
                    <button key={heading["id"]} className='ContentValue text-left' onClick={() => handleClickAndKeyPress(heading)} onKeyPress={() => handleClickAndKeyPress(heading)}>
                      {heading["value"]}
                    </button>
                  )
                })
            }
            </div>
        </div > 
    )
}

export default RightSidebar
