import React from 'react'
import { Link } from 'gatsby'
import { FaRegFileAlt } from 'react-icons/fa'

const LearningPathNotification = ({ associatedLearningPaths, hideLearnNotify, allTutorials, tutorial }) => {

  const learningPathMessage = `
    This tutorial is part of ${associatedLearningPaths.length > 1 ? 'multiple' : 'a'} 
    Couchbase Learning Path${associatedLearningPaths.length > 1 ? 's' : ''}:
  `
  const associatedLearnPathsMenus = associatedLearningPaths.map((learn, idx) => {
    const tutorials = learn.frontmatter.tutorials
    const learningPath = learn.frontmatter.path.substring(1)
    const currentTutorialIndex = tutorials.indexOf(tutorial.frontmatter.path.substring(1))

    let tutorialSet = []
    tutorials.forEach((path) => {
      const thisTut = allTutorials.nodes.filter(
        elem => elem.frontmatter.path.substring(1) === path
      )
      tutorialSet.push({
        path: path,
        short_title: thisTut[0].frontmatter.short_title,
        learningPath: learn
      })
    })

    const prevTutorialObject = tutorialSet[currentTutorialIndex - 1] || null
    const nextTutorialObject = tutorialSet[currentTutorialIndex + 1] || null

    return (
      <div className={`pt-2 pb-2 px-3 mt-2 rounded ${idx % 2 ? '' : 'bg-blue-50'}`}>
        <div key={`${learningPath}-menu-${idx}`} className={`learn-navigation`}>
          <div className="flex flex-row font-semibold text-blue-600">
            <div className="w-1/3 mt-2">
              <div className="flex flex-col justify-start">
                {prevTutorialObject !== null
                  ? <>
                    <div className="text-left mb-4">
                      <Link className="rounded py-1 px-2 sm:py-1 sm:px-2 md:py-2 md:px-4 lg:py-2 lg:px-4 
                        bg-blue-600 text-white border-2 border-blue-600 hover:bg-white hover:text-blue-600"
                        to={`/${prevTutorialObject.path}?learningPath=${learningPath}`}>
                        PREV
                      </Link>
                    </div>
                    <div className="text-left text-xs">
                      {prevTutorialObject.short_title}
                    </div>
                  </>
                  : null
                }
              </div>
            </div>
            <div className="w-1/3 mt-2">
              <div className="text-center mb-4">
                <Link className="rounded py-1 px-2 sm:py-1 sm:px-2 md:py-2 md:px-4 lg:py-2 lg:px-4 
                  bg-blue-600 text-white border-2 border-blue-600 hover:bg-white hover:text-blue-600"
                  to={`${learn.frontmatter.path}`}>
                  HOME
                </Link>
              </div>
              <div className="text-center text-xs">
                {learn.frontmatter.title}
              </div>
            </div>
            <div className="w-1/3 mt-2 items-end">
              <div className="flex flex-col justify-end">
                {nextTutorialObject !== null
                  ? <>
                    <div className="text-right mb-4">
                      <Link className="rounded py-1 px-2 sm:py-1 sm:px-2 md:py-2 md:px-4 lg:py-2 lg:px-4 
                        bg-blue-600 text-white border-2 border-blue-600 hover:bg-white hover:text-blue-600"
                        to={`/${nextTutorialObject.path}?learningPath=${learningPath}`}>
                        NEXT
                      </Link>
                    </div>
                    <div className="text-right text-xs">
                      {nextTutorialObject.short_title}
                    </div>
                  </>
                  : null
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  })

  return (
    <div className={`${hideLearnNotify ? 'hidden' : 'learn-notify mt-6'} rounded border border-blue-600 p-3 mb-6`}>
      <div className="flex flex-col text-blue-600">
        <div className="mt-2 font-semibold">
          <FaRegFileAlt style={{ fontSize: 'x-large', marginTop: '-0.25em', marginRight: '0.5em', display: 'inline' }} />
          {learningPathMessage}
        </div>
        <div className="mt-4">
          {associatedLearnPathsMenus}
        </div>
      </div>
    </div>
  )
}

export default LearningPathNotification